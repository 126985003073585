import { Link } from "gatsby"
import React, { useState, useRef, useEffect } from "react"
import Image from "./image"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Popover from "@material-ui/core/Popover"
import Typography from "@material-ui/core/Typography"
import ExpandIcon from "@material-ui/icons/ExpandMore"
//redux
import { connect, useSelector } from "react-redux"
import MobileMenu from "./common/MobileMenu"
import { changeLang } from "../redux/actions/languageAction"
import { changeRoute } from "../redux/actions/currentPageAction"
import dictionary from "../languages"
import { useTheme } from "@material-ui/core/styles"
import { Menu, ArrowForward, ArrowBack } from "@material-ui/icons"
import _ from "lodash"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import data from "../data.json"
import { Paper } from "@material-ui/core"
const useStyles = makeStyles(theme => ({
  toolbarSecondary: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarButton: {
    padding: theme.spacing(1),
    flexShrink: 0,
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
    color: "white",
    flex: "1",
  },
  selected: {
    padding: theme.spacing(1),
    flexShrink: 0,
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
    color: "#495f14",
    backgroundColor: "white",
  },
  toolbarNavLink: {
    textDecoration: "none",
    display: "flex",
    position: "relative",
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "400px",
  },
  popoverMobile: {
    display: "flex",
    flexDirection: "column",
    left: "1px",
  },
  subMenuWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  subpageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconWrapper: {
    maxWidth: `40px`,
    flex: "1",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  link: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
  },
}))

const FolderMenu = ({
  page,
  isPopover,
  handleRoute,
  currentPage,
  firstMenu,
}) => {
  const classes = useStyles()
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)
  const buttonStyle = {
    color: firstMenu && !isPopover ? "white" : "#6d7f41",
    justifyContent: lang === "en" ? "flex-start" : "flex-end",
  }
  const [open, setOpen] = useState(false)
  const handleHoverIn = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false)
    }, 50)
  }
  return (
    <div
      key={`MenuBar-${page.slug}`}
      className={classes.subpageWrapper}
      style={{ direction: `${dir}` }}
    >
      <Link
        key={page.slug}
        to={`/${page.slug === "/" ? "" : page.slug}`}
        className={classes.toolbarNavLink}
        style={{ flex: "1" }}
      >
        <Button
          style={buttonStyle}
          key={`button-${page.slug}`}
          className={classes.toolbarButton}
          onClick={() => handleRoute(page.slug)}
          onMouseEnter={handleHoverIn}
          onMouseLeave={handleClose}
          aria-describedby={`popover-area-${page.slug}`}
        >
          <span
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "space-between",
            }}
          >
            {lang === "en" ? page.title_en : page.title_fa}
            {!firstMenu && lang === "en" ? (
              <ArrowForward></ArrowForward>
            ) : (
              <ArrowBack></ArrowBack>
            )}
          </span>
        </Button>
        <SubMenu
          key={`subMenu-${page.slug}`}
          data={page}
          handleRoute={handleRoute}
          currentPage={currentPage}
          lang={lang}
          firstMenu={firstMenu}
          parentOpen={open}
          parentHandleClose={handleClose}
        ></SubMenu>
      </Link>
    </div>
  )
}
const MenuBar = ({ data, currentPage, handleRoute, isPopover, ...props }) => {
  const classes = useStyles()
  const lang = useSelector(state => state.language.lang)
  const firstMenu = Object.keys(data).length === 1
  const buttonStyle = {
    color: firstMenu && !isPopover ? "white" : "#6d7f41",
    justifyContent: lang === "en" ? "flex-start" : "flex-end",
  }
  const content = data.pages || data

  return content.map(page => {
    if (page.content_en || page.content_fa) {
      //Handle Page
      if (page.subpages) {
        return (
          <FolderMenu
            key={`Folder-${page.slug}`}
            page={page}
            isPopover={isPopover}
            handleRoute={handleRoute}
            currentPage={currentPage}
            firstMenu={firstMenu}
          ></FolderMenu>
        )
      } else {
        let link = page.slug
        if (link === "/") {
          link = "home"
        }
        return (
          <Link
            key={page.slug}
            to={`/${link === "home" ? "" : link}`}
            className={classes.toolbarNavLink}
          >
            <Button
              style={buttonStyle}
              key={`button-${page.slug}`}
              className={classes.toolbarButton}
              onClick={() => handleRoute(link)}
            >
              {lang === "en" ? page.title_en : page.title_fa}
            </Button>
          </Link>
        )
      }
    } else {
      let link = page.link
      if (link === "/") {
        link = "home"
      }
      return (
        <div key={`MenuBar-${page.slug}`} className={classes.subMenuWrapper}>
          <SubMenu
            data={page}
            key={`subMenu-${page.slug}`}
            buttonStyle={buttonStyle}
            handleRoute={handleRoute}
            currentPage={currentPage}
            lang={lang}
            firstMenu={firstMenu}
          ></SubMenu>
        </div>
      )
    }
  })
}

const SubMenu = ({
  data,
  handleRoute,
  buttonStyle,
  currentPage,
  lang,
  firstMenu,
  parentOpen,
  parentHandleClose,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const id = open ? "simple-popover" : undefined
  const content = data.subpages ? data.subpages : data

  const handleClick = () => {
    setOpen(prev => !prev)
  }
  const handleHoverIn = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const ref = useRef()
  const handleClickOutside = e => {
    if (ref.current && ref.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setOpen(false)
  }
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open])

  return (
    <>
      {firstMenu && (
        <div className={classes.toolbarNavLink} ref={ref}>
          <Button
            style={buttonStyle}
            aria-describedby={`${id}${data.slug}`}
            key={content.slug}
            className={classes.toolbarButton}
            onClick={handleClick}
          >
            {lang === "en" ? content.title_en : content.title_fa}
            {/* || (
          <ExpandIcon style={{ color: "#6d7f41" }} />
        ) */}
          </Button>

          <Paper
            className={classes.popover}
            key={`popover-${data.slug}`}
            id={`${id}${data.slug}`}
            onClose={handleClose}
            style={{
              top: "75px",
              display: open ? "flex" : "none",
              zIndex: "2",
              direction: "ltr",
            }}
          >
            <MenuBar
              data={content}
              currentPage={currentPage}
              handleRoute={handleRoute}
              props
            />
          </Paper>
        </div>
      )}
      {!firstMenu && (
        <Paper
          className={classes.popover}
          key={`popover-${data.slug}`}
          style={{
            display: open || parentOpen ? "flex" : "none",
            left: lang === "en" ? "100%" : "-100%",
            direction: "ltr",
          }}
          ref={ref}
        >
          <div
            onMouseEnter={handleHoverIn}
            onMouseLeave={() => {
              setOpen(false)
              parentHandleClose()
            }}
          >
            <MenuBar
              data={content}
              currentPage={currentPage}
              handleRoute={handleRoute}
              props
            />
          </div>
        </Paper>
      )}
    </>
  )
}

const ToolbarMenu = ({
  sections,
  handleCurrentPage,
  currentPage,
  data,
  isPopover,
}) => {
  const classes = useStyles()
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)
  return (
    <Toolbar
      component="nav"
      variant="dense"
      className={classes.toolbarSecondary}
      style={{
        flexDirection: isPopover ? "column" : "row",
        alignItems: lang === "en" ? "flex-start" : "flex-end",
      }}
    >
      {/* <Link
        key={sections[0].link}
        to={sections[0].link}
        className={classes.toolbarNavLink}
      >
        <Button
          key={sections[0]}
          className={classes.toolbarButton}
          onClick={() => handleCurrentPage(sections[0].link)}
          style={{ color: isPopover ? "#6d7f41" : "white" }}
        >
          {sections[0].name}
        </Button>
      </Link> */}
      <MenuBar
        data={data}
        handleRoute={handleCurrentPage}
        currentPage={currentPage}
        isPopover={isPopover}
      ></MenuBar>
    </Toolbar>
  )
}

const Header = ({
  siteTitle,
  lang,
  dir,
  changeLang,
  changeRoute,
  current_page,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const isPhoneLandscape = useMediaQuery(theme.breakpoints.up("md"))

  const [dict, setDict] = React.useState(dictionary("en"))
  const [currentPage] = React.useState(current_page)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const sections = [
    { name: dict.home, link: "/" },
    // { name: dict.products, link: "/products/" },
    // { name: dict.aboutUs, link: "/aboutus/" },
    // { name: dict.articles, link: "/articles/" },
    // { name: dict.news, link: "/news/" },
    { name: dict.contactUs, link: "/contactus/" },
  ]
  const handleCurrentPage = selected => {
    changeRoute(selected)
  }
  const changeLanguage = lang => {
    changeLang(lang)
  }
  React.useEffect(() => {
    setDict(dictionary(lang))
  }, [lang])

  return (
    <header
      style={
        {
          // marginBottom: "8vh",
        }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100px",
          height: "100",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            justifyContent: "center",
            backgroundColor: "#6d8041",
            direction: `${dir}`,
            flexWrap: "wrap",
          }}
        >
          {!isPhoneLandscape && (
            <>
              <Button
                style={{ color: "white" }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <Menu></Menu>
              </Button>
              <Popover
                classes={{
                  paper: classes.popoverMobile,
                }}
                className={classes.popover}
                key={`popover-menu`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style:
                    lang === "en"
                      ? {
                          marginLeft: "-16px",
                        }
                      : {
                          marginLeft: "16px",
                        },
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MobileMenu
                  sections={sections}
                  data={data}
                  handleCurrentPage={handleCurrentPage}
                ></MobileMenu>
              </Popover>
            </>
          )}
          <div style={{ flex: "1", display: isDesktop ? "flex" : "none" }}>
            <Link
              to="/"
              style={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => handleCurrentPage("home")}
            >
              <div style={{ maxWidth: `40px`, flex: "1", paddingTop: "20px" }}>
                <Image fileName="small-logo2.png" alt="logo" />
              </div>
            </Link>
          </div>
          <div
            style={{
              flexDirection: "row",
              flex: "8",
              justifyContent: "flex-start",
              display: isDesktop && isPhoneLandscape ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <ToolbarMenu
              key={"ToolbarMenu"}
              isPopover={false}
              sections={sections}
              handleCurrentPage={handleCurrentPage}
              data={data}
              currentPage={currentPage}
            ></ToolbarMenu>
          </div>

          <div
            style={{
              flex: "1",
              justifyContent: "flex-end",
              display: "flex",
              backgroundColor: "rgb(109, 128, 65)",
            }}
          >
            <a
              className={classes.link}
              href="http://linkedin.com/in/sadaf-sabz-5878b51b0"
            >
              <Button>
                <div className={classes.iconWrapper}>
                  <Image fileName="linkdin.png" alt="Linkdin" />
                </div>
              </Button>
            </a>
            <a
              className={classes.link}
              href="https://instagram.com/pistachio_sadafsabz?igshid=5w36k3vyiuhs"
            >
              <Button>
                <div className={classes.iconWrapper}>
                  <Image fileName="Instagram.png" alt="Instagram" />
                </div>
              </Button>
            </a>
            <a
              className={classes.link}
              href="https://www.facebook.com/sadaf.sabz"
            >
              <Button>
                <div className={classes.iconWrapper}>
                  <Image fileName="facebook.png" alt="Facebook" />
                </div>
              </Button>
            </a>
            <Divider
              orientation="vertical"
              style={{
                alignSelf: "center",
                height: "80%",
                margin: "0px 1.2vw 0px 1.2vw",
              }}
            ></Divider>
            <Button style={{ flex: "1" }} onClick={() => changeLanguage("fa")}>
              <div className={classes.iconWrapper}>
                <Image fileName="icons8-iran-48.png" alt="فارسی" />
              </div>
            </Button>
            <Button style={{ flex: "1" }} onClick={() => changeLanguage("en")}>
              <div className={classes.iconWrapper}>
                <Image fileName="icons8-usa-48.png" alt="English" />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Divider />
    </header>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
    current_page: state.current_page.current_page,
  }
}
export default connect(mapStateToProps, { changeLang, changeRoute })(Header)
