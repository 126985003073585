export default {
  search: "Search",
  awards: "Awards:",
  home: "Home",
  product: "Product",
  products: "Products",
  aboutUs: "About Us",
  articles: "Articles",
  news: "News",
  contactUs: "Contact Us",
  otherInformation: {
    title: "Other Information",
    addressTitle: "Address: ",
    addressDetail: "4th km of ghaem boulevard(zangi abbad road)",
    telTitle: "Tel: ",
    telDetail: "+9834-32752542",
    faxTitle: "Fax: ",
    faxDetail: "+9834-32752543",
    emailTitle: "Email: ",
    email1: "info@sadafsabz.com",
    email2: "SADAF_SABZ@YAHOO.COM",
  },
  rightReserved: ` © ${new Date().getFullYear()} All rights reserved for Sadaf Sabz
  Compnay.`,
  sendEmail: "Send a message to us",
  contactUsForm: {
    firstName: "First Name",
    lastName: "Last Name",
    message: "Message",
    subject: "Subject",
    send: "Send",
    email: "Email",
  },
  aboutProduct: "About This Product",
  someOfOurProducts: "Some of Sadaf Sabz pistachio product group",
  someOfOurProductsSub: "To see more of our wide variety of products please choose a group",
  checkoutProductSheet: "For more information checkout the product specification here"
}
