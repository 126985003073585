import React, { useState, useEffect } from "react"
import data from "../../../data.json"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Popover } from "@material-ui/core"
import { ExpandIcon, ArrowForward, ArrowBack } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  menuWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  toolbarSecondary: {
    display: "flex",
    justifyContent: "space-between",
  },
  navLink: {
    textDecoration: "none",
    display: "flex",
  },
  toolbarButton: {
    padding: theme.spacing(1),
    flexShrink: 0,
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
    color: "#6d7f41",
  },
  folderBtn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
  },
}))
const Folder = ({ data, menuHandler }) => {
  const lang = useSelector(state => state.language.lang)
  const classes = useStyles()

  return (
    <div className={classes.folderBtn}>
      {data.content_en && (
        <Link
          className={classes.navLink}
          style={{ display: "flex" }}
          to={`/${data.slug === "/" ? "" : data.slug}`}
        >
          <Button className={classes.toolbarButton}>
            {lang === "en" ? data.title_en : data.title_fa}
          </Button>
        </Link>
      )}
      {!data.content_en && (
        <div className={classes.navLink} style={{ display: "flex" }}>
          <Button className={classes.toolbarButton}>
            {lang === "en" ? data.title_en : data.title_fa}
          </Button>
        </div>
      )}
      <Button
        className={classes.toolbarButton}
        onClick={() => {
          menuHandler(data)
        }}
        style={{
          paddingLeft: lang === "en" ? "20px" : "0px",
          paddingRight: lang === "en" ? "0px" : "20px",
          display: "flex",
        }}
      >
        {lang === "en" ? (
          <ArrowForward></ArrowForward>
        ) : (
          <ArrowBack></ArrowBack>
        )}
      </Button>
    </div>
  )
}
const NavLink = ({ data }) => {
  const lang = useSelector(state => state.language.lang)
  const classes = useStyles()
  return (
    <Link
      to={`/${data.slug === "/" ? "" : data.slug}`}
      className={classes.navLink}
    >
      <Button className={classes.toolbarButton}>
        {lang === "en" ? data.title_en : data.title_fa}
      </Button>
    </Link>
  )
}
const renderBtns = (data, menuHandler) => {
  return data.map(page => {
    if (page.content_en) {
      if (!page.subpages) {
        return <NavLink key={`NavLin-${page.slug}`} data={page}></NavLink>
      } else {
        return (
          <Folder
            key={`Folder-${page.slug}`}
            menuHandler={menuHandler}
            data={page}
          ></Folder>
        )
      }
    } else {
      return (
        <Folder
          key={`Folder-${page.slug}`}
          menuHandler={menuHandler}
          data={page}
        ></Folder>
      )
    }
  })
}

const MobileMenu = ({ sections, data, handleCurrentPage }) => {
  const classes = useStyles()
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)

  const [dataContent, setDataContent] = useState(data)
  const firstMenu = Object.keys(dataContent).length === 1
  const content = firstMenu
    ? dataContent.pages
    : dataContent.content_en
    ? dataContent.subpages
    : dataContent.pages

  const menuHandler = newData => {
    setDataContent(newData)
  }
  return (
    <div style={{ minWidth: "350px", minHeight: "250px" }}>
      <div className={classes.menuWrapper} style={{ direction: dir }}>
        {!firstMenu && (
          <Button
            className={classes.toolbarButton}
            onClick={() => {
              menuHandler(data)
            }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {lang === "en" ? (
              <ArrowBack></ArrowBack>
            ) : (
              <ArrowForward></ArrowForward>
            )}
          </Button>
        )}
        {/* {firstMenu && (
          <Link
            key={sections[0].link}
            to={sections[0].link}
            className={classes.navLink}
          >
            <Button
              key={sections[0]}
              className={classes.toolbarButton}
              onClick={() => handleCurrentPage(sections[0].link)}
            >
              {sections[0].name}
            </Button>
          </Link>
        )} */}
        {renderBtns(content, menuHandler)}
      </div>
    </div>
  )
}

export default MobileMenu
