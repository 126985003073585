import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { makeStyles } from "@material-ui/core/styles"

import ContactFrom from "../components/common/ContactForm/ContactForm"

import { connect } from "react-redux"
import dictionary from "../languages"

import data from "../data.json"

const useStyles = makeStyles(theme => ({
  contactusWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: "1",
    marginTop: "2vh",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  information: {
    fontSize: "16pt",
    lineHeight: "2rem",
  },
}))
export const filterContact = (data, lang) => {
  const filteredData = data.allPrismicContact.edges.filter(node => {
    if (lang === "fa") {
      return node.node.lang === "fa-ir"
    } else if (lang === "en") {
      return node.node.lang === "en-us"
    }
    return false
  })
  return filteredData
}

const ContactUs = ({ lang, dir }) => {
  const classes = useStyles()
  const [dict, setDict] = React.useState(dictionary(lang))

  React.useEffect(() => {
    setDict(dictionary(lang))
  }, [lang])
  const content = data.pages.find(page => page.type === "contactus")
  const contactUsData = lang === "en" ? content.detail_en : content.detail_fa

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className={classes.contactusWrapper}>
        <div
          style={{
            flex: "1",
          }}
        >
          <h2 style={{ textAlign: "center", color: "#51671b" }}>
            {" "}
            {dict.contactUs}
          </h2>
        </div>
        <div
          className={classes.root}
          style={{
            direction: `${dir}`,
            borderColor: "#00000024",
            borderWidth: "thin",
            borderStyle: "groove",
            padding: "10px",
            borderRadius: "1%",
          }}
        >
          {/* <div className={classes.contactusWrapper}>
            <ContactFrom
              style={{
                flex: "1",
              }}
              firstName={dict.contactUsForm.firstName}
              lastName={dict.contactUsForm.lastName}
              message={dict.contactUsForm.message}
              subject={dict.contactUsForm.subject}
              email={dict.contactUsForm.email}
              send={dict.contactUsForm.send}
            ></ContactFrom>
          </div> */}
          <div
            style={{
              flex: "1",
              display: "flex",
              borderRadius: "1%",
              color: "#7c7c7c",
              margin: "5vh 0px 5vh 0px",
            }}
          >
            <div
              style={{
                flex: "1",
                paddingTop: "20px",
                direction: `${dir}`,
                margin: "auto",
              }}
            >
              <p
                className={classes.information}
                style={{ textAlign: `${dir}`, padding: "20px" }}
              >
                <strong className={classes.information}>
                  {" "}
                  {dict.otherInformation.addressTitle}
                </strong>{" "}
                {contactUsData.address}
                <br></br>
                <strong className={classes.information}>
                  {dict.otherInformation.telTitle}
                </strong>{" "}
                <span>&#x200E;{contactUsData.tele}</span>
                <br></br>
                <strong className={classes.information}>
                  {dict.otherInformation.faxTitle}
                </strong>{" "}
                <span>&#x200E;{contactUsData.fax}</span>
                <br></br>
                <strong className={classes.information}>
                  {dict.otherInformation.emailTitle}
                </strong>{" "}
                {contactUsData.email2}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(ContactUs)
