import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { makeStyles } from "@material-ui/core/styles"

import { connect } from "react-redux"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import data from "../data.json"
import { Paper, Button, IconButton, Popover } from "@material-ui/core"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"

const useStyles = makeStyles(theme => ({
  toolbarButton: {
    padding: theme.spacing(1),
    flexShrink: 0,
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
    color: "#6d7f41",
    flex: "1",
  },
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
    maxWidth: "1200px",
    alignItems: "center",
    minWidth: "100%",
  },
  mobileIcon: {
    left: "calc(100% - 70px)",
    position: "fixed",
    top: "calc(100% - 70px)",
    backgroundColor: "#8080807a",
    zIndex: "10",
  },
}))
const renderArticles = (articles, setArticle, lang, classes, handleClose) => {
  return articles.map(article => {
    return (
      <div
        key={`Key_${article.title_en}`}
        style={{ display: "flex", flex: "1" }}
      >
        <Button
          onClick={() => {
            setArticle(article.title_en)
            if (handleClose) {
              handleClose()
            }
          }}
          className={classes.toolbarButton}
        >
          {lang === "en" ? article.title_en : article.title_fa}
        </Button>
      </div>
    )
  })
}
const ArticlePage = ({ lang, dir }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  const content = data.pages.find(page => page.type === "articles")

  const [language, setLanguage] = React.useState(lang)
  const [article, setArticle] = React.useState(content.articles[0])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeArticle = articleTitle => {
    const newArticle = content.articles.find(
      article => article.title_en === articleTitle
    )
    setArticle(newArticle)
  }
  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  let textAlign = "left"
  if (dir === "rtl") {
    textAlign = "right"
  }

  let selfAlign = "flex-start"
  if (dir === "rtl") {
    selfAlign = "flex-end"
  }

  return (
    <Layout>
      <SEO title="About us" />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2vh",
        }}
      >
        <div className={classes.contentWrapper}>
          <div
            className={classes.detailWrapper}
            style={{ textAlign: `${textAlign}` }}
            dangerouslySetInnerHTML={{
              __html: lang === "en" ? article.content_en : article.content_fa,
            }}
          ></div>
          {isDesktop && (
            <div style={{ position: "relative", flex: "1" }}>
              <Paper
                style={{
                  width: "300px",
                  height: "400px",
                  position: "fixed",
                  overflow: "auto",
                }}
              >
                {renderArticles(content.articles, changeArticle, lang, classes)}
              </Paper>
            </div>
          )}
          {!isDesktop && (
            <div style={{ flex: "0.5", position: "relative" }}>
              <IconButton
                aria-describedby={id}
                onClick={handleClick}
                className={classes.mobileIcon}
              >
                <DescriptionOutlinedIcon></DescriptionOutlinedIcon>
              </IconButton>
              <Popover
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{
                  paper: classes.popoverMobile,
                }}
                className={classes.popover}
                key={`popover-menu`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div style={{ position: "relative", flex: "1" }}>
                  {renderArticles(
                    content.articles,
                    changeArticle,
                    lang,
                    classes,
                    handleClose
                  )}
                </div>
              </Popover>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(ArticlePage)
