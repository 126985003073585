import englishDict from "./English"
import farsiDict from "./Farsi"

export default lang => {
  if (lang === "en") {
    return englishDict
  } else if (lang === "fa") {
    return farsiDict
  }
}
