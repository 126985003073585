import { Link } from "gatsby"
import React from "react"
import Divider from "@material-ui/core/Divider"
import Image from "./image"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { connect } from "react-redux"
import dictionary from "../languages"
import { useContactUsData, filterContact } from "../pages/contactus"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import data from "../data.json"
const useStyles = makeStyles(theme => ({
  awardImage: { maxWidth: `30px`, flex: "1", margin: "0.4rem" },
  rightReserved: {
    display: "flex",
    justifyContent: "center",
    flex: "1",
    alignSelf: "center",
  },
  navButton: {
    flex: "1",
    fontSize: "9pt",
    width: "60%",
    textDecoration: "none",
    alignSelf: "center",
    boxShadow: "none",
  },
  navLink: {
    textDecoration: "none",
    display: "flex",
  },
  information: {
    fontSize: "10pt",
  },
}))

const Footer = ({ lang, dir, changeLang }) => {
  const classes = useStyles()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  const [dict, setDict] = React.useState(dictionary(lang))

  React.useEffect(() => {
    setDict(dictionary(lang))
  }, [lang])

  const content = data.pages.find(page => page.type === "contactus")
  const contactUsData = lang === "en" ? content.detail_en : content.detail_fa

  return (
    <footer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "2",
            marginBottom: "5vh",
            flexWrap: "wrap",
          }}
        >
          <Divider
            variant="middle"
            style={{
              flex: "1",
              marginTop: "20px",
            }}
          />
          <div style={{ maxWidth: `30px`, flex: "1" }}>
            <Image fileName="small-logo.jpg" alt="logo" />
          </div>
          <Divider
            variant="middle"
            style={{
              flex: "1",
              marginTop: "20px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            direction: `${dir}`,
          }}
        >
          <div
            style={{
              flex: "1",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: "2",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                direction: `${dir}`,
              }}
            >
              <Link to="/aboutus" className={classes.navLink}>
                <Button className={classes.navButton}>{dict.aboutUs}</Button>
              </Link>
              <Link to="/contactus" className={classes.navLink}>
                <Button className={classes.navButton}>{dict.contactUs}</Button>
              </Link>
            </div>
          </div>

          <div
            style={{
              flex: "1",
              paddingTop: "20px",
              direction: `${dir}`,
              display: isDesktop ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <h5 style={{ textAlign: "center" }}>
              {dict.otherInformation.title}
            </h5>
            <p className={classes.information} style={{ textAlign: "center" }}>
              <strong className={classes.information}>
                {" "}
                {dict.otherInformation.addressTitle}
              </strong>{" "}
              {contactUsData.address}
              <br></br>
              <strong className={classes.information}>
                {dict.otherInformation.telTitle}
              </strong>{" "}
              <span>&#x200E;{contactUsData.tele}</span>
              <br></br>
              <strong className={classes.information}>
                {dict.otherInformation.faxTitle}
              </strong>{" "}
              <span>&#x200E;{contactUsData.fax}</span>
              <br></br>
              <strong className={classes.information}>
                {dict.otherInformation.emailTitle}
              </strong>{" "}
              {contactUsData.email2}
              <br></br>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "20px",
              flexDirection: "column",
              flex: "1",
            }}
          >
            <p
              style={{
                flex: "0.1",
                marginLeft: "0.4rem",
                textAlign: "center",
              }}
            >
              {dict.awards}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <div className={classes.awardImage}>
                <Image fileName="awards-1.jpg" alt="award-1" />
              </div>
              <div className={classes.awardImage}>
                <Image fileName="awards-2.jpg" alt="award-2" />
              </div>
              <div className={classes.awardImage}>
                <Image fileName="awards-3.jpg" alt="award-3" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <div className={classes.awardImage}>
                <Image fileName="awards-4.jpg" alt="award-4" />
              </div>
              <div className={classes.awardImage}>
                <Image fileName="awards-5.jpg" alt="award-5" />
              </div>
              <div className={classes.awardImage}>
                <Image fileName="awards-6.jpg" alt="award-6" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "3",
            direction: `${dir}`,
            fontSize: isDesktop ? "1rem" : "10pt",
          }}
        >
          <p className={classes.rightReserved}>{dict.rightReserved}</p>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(Footer)
