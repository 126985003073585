import React from "react"
import { Form, Field } from "react-final-form"
import { Paper, Grid, Button, CssBaseline, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
const useStyles = makeStyles(theme => ({
  submitButton: {
    backgroundColor: "#95ba3b",
    color: "white",
    padding: "10px 30px 10px 30px",
  },
}))

//must handle
const onSubmit = async values => {}
const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = "Required"
  }
  if (!values.lastName) {
    errors.lastName = "Required"
  }
  if (!values.email) {
    errors.email = "Required"
  }
  return errors
}

const ContactForm = ({
  firstName = "First Name",
  lastName = "Last Name",
  email = "Email",
  subject = "Subject",
  message = "Message",
  send = "Send",
  dir,
}) => {
  const classes = useStyles()
  return (
    <div
      style={{
        padding: 16,
        margin: "auto",
        maxWidth: 600,
        direction: `${dir}`,
      }}
    >
      <CssBaseline />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16 }} variant="outlined">
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="firstName"
                    component={TextField}
                    type="text"
                    placeholder={firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="lastName"
                    component={TextField}
                    type="text"
                    placeholder={lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    fullWidth
                    required
                    component={TextField}
                    type="email"
                    placeholder={email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="subject"
                    fullWidth
                    required
                    component={TextField}
                    type="subject"
                    placeholder={subject}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="Message"
                    component={TextField}
                    multiline
                    placeholder={message}
                    variant="outlined"
                    rowsMax="4"
                    rows="4"
                  />
                </Grid>

                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting}
                    className={classes.submitButton}
                  >
                    {send}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </div>
  )
}
const mapStateToProps = state => {
  return {
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(ContactForm)
