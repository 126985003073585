export default {
  search: "جست و جو",
  awards: "نشان اعتماد",
  home: "صفحه‌ی اصلی",
  products: "محصولات",
  product: "محصول",
  aboutUs: "درباره‌ی ما",
  articles: "مقالات",
  news: "اخبار",
  contactUs: "تماس با ما",
  otherInformation: {
    title: "راه‌های ارتباط با ما",
    addressTitle: "آدرس: ",
    addressDetail: "کرمان - کیلومتر 4 جاده زنگی آباد",
    telTitle: "تلفن: ",
    telDetail: "۳۲۷۵۲۵۴۲-۰۳۴",
    faxTitle: "فکس:",
    faxDetail: "۳۲۷۵۲۵۴۲-۰۳۴",
    emailTitle: "آدرس ایمیل: ",
    email1: "info@sadafsabz.com",
    email2: "SADAF_SABZ@YAHOO.COM",
  },
  rightReserved: `©${new Date().getFullYear()}  کلیه حقوق مادی و معنوی وب سایت برای صدف سبز محفوظ می باشد. `,
  sendEmail: "برای ما پیغام بگذارید ",
  contactUsForm: {
    firstName: "نام",
    lastName: "نام خانوادگی",
    message: "پیغام",
    subject: "موضوع",
    send: "ارسال",
    email: "آدرس ایمیل",
  },
  aboutProduct: "درباره‌ی این محصول",
  someOfOurProducts: "بعضی از محصولات ما",
  someOfOurProductsSub: "برای دیدن بیشتر محصولات متنوع ما، لطفاً گروهی را انتخاب کنید",
  checkoutProductSheet: "برای اطلاعات بیشتر مشخصات محصول را بررسی کنید"

}
